import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 27.1 22.1"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M0 10.8v.6c0 .1.1.1.1.2 0 0 0 .1.1.1l8.9 10c.4.4 1 .5 1.5.1s.5-1 .1-1.5l-7.5-8.5h22.9c.6 0 1-.5 1-1s-.5-1-1-1H3.5l7.2-8.1c.4-.4.4-1.1-.1-1.5-.1-.1-.4-.2-.6-.2-.3 0-.6.1-.8.3L.5 10c-.1.1-.1.1-.1.2l-.1.1s0 .1-.1.1c0 .1-.1.1-.1.2s0 .2-.1.2",
      class: "st0"
    }, null, -1)
  ])))
}
export default { render: render }