import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 520 840"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M282 31.8C269.9 12.3 244.9 0 217.5 0H0v840h217.5c27.4 0 52.4-12.3 64.5-31.8l225.3-363.1c9.8-15.8 9.8-34.4 0-50.2z" }, null, -1)
  ])))
}
export default { render: render }