import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 10 10"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#000",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M8 8h-.5a1 1 0 0 1 0-2H8a1 1 0 0 1 0 2"
    }, null, -1),
    _createElementVNode("path", {
      stroke: "#000",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M9 7v1.5a1 1 0 0 1-1 1H6.5M6.5.5h1.25v3M6.5 3.5H9M2.5.5v9M.75 7.75 2.5 9.5l1.75-1.75"
    }, null, -1)
  ])))
}
export default { render: render }