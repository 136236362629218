import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 10 10"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#000",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M3.576 6.424.5 9.5M1.931 3.638a.519.519 0 0 0-.265.876l3.82 3.82a.519.519 0 0 0 .876-.265l.588-2.943 2.31-1.469a.52.52 0 0 0 .088-.805l-2.2-2.2a.52.52 0 0 0-.8.088L4.874 3.05z"
    }, null, -1)
  ])))
}
export default { render: render }