import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 10 10"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#000",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M4.308.877a.82.82 0 0 1 1.38 0L7.033 3l1.754.217a.81.81 0 0 1 .659.508.8.8 0 0 1-.141.813L7.848 6.221l.47 2.316A.8.8 0 0 1 8 9.344a.82.82 0 0 1-.875.054L4.998 8.232 2.873 9.4a.82.82 0 0 1-1.155-.411.8.8 0 0 1-.041-.45l.47-2.317-1.452-1.68a.8.8 0 0 1 .518-1.32L2.963 3z"
    }, null, -1)
  ])))
}
export default { render: render }