import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 27.14 22.1"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M27.11 11.26c.01-.07.03-.13.03-.2s-.01-.13-.03-.2a.6.6 0 0 0-.05-.18c-.03-.07-.07-.13-.11-.19-.03-.04-.04-.08-.07-.12L17.97.35c-.39-.43-1.04-.47-1.47-.09s-.47 1.04-.09 1.47l7.54 8.46H1.04a1.04 1.04 0 1 0 0 2.08H23.6l-7.2 8.07a1.033 1.033 0 0 0 .78 1.73c.29 0 .57-.12.78-.35l8.67-9.73a.7.7 0 0 0 .14-.16l.1-.11s.04-.08.07-.11c.04-.06.09-.12.11-.2.02-.06.03-.12.05-.18Z",
      style: {"fill":"#122233","stroke-width":"0"}
    }, null, -1)
  ])))
}
export default { render: render }