import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 10 10"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      stroke: "#000",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      d: "M2.5.5v9M.75 7.75 2.5 9.5l1.75-1.75M6 4 7.5.5 9 4M6.322 3.25h2.357M6.5 6.5H9l-2.5 3H9"
    }, null, -1)
  ])))
}
export default { render: render }